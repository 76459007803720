import React, { createElement, Fragment } from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { unified } from "unified";
import { h } from "hastscript";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
import styled from "@emotion/styled";
import type { PageProps } from "gatsby";
import type { Element } from "hast";
import { useRecoilState } from "recoil";

import "katex/dist/katex.min.css";

import Image from "../../components/Image";
import ExternalLink from "../../components/ExternalLink";
import BlockQuote from "../../components/BlockQuote";
import DivProxy from "../../components/DivProxy";
import Admonition from "../../components/Admonition";
import TreeView from "../../components/TreeView";
import HeadImage from "../../components/HeadImage";
import dayjs from "../../utils/dayjs";
import Tag from "../../components/Tag";
import breakpoints from "../../utils/breakpoints";
import i18n from "../../utils/i18n";
import langAtom from "../../atoms/lang";

const ArticlePageStyles = styled.div`
  margin: 1rem;

  .article-front {
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;

    h1 {
      font-size: 3rem;
    }

    .meta-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 6px;
      vertical-align: text-bottom;
    }

    .tag {
      margin-right: 6px;
    }

    hr {
      margin: 1rem 0;
    }
  }

  article {
    margin-bottom: 100px;
    & > * {
      margin-right: auto;
      margin-left: auto;
      max-width: 800px;
    }

    & > h1 {
      margin-top: 3rem;
      font-size: xx-large;
      color: ${(props) => props.theme.article?.heading};
    }

    & > h2 {
      margin-top: 2rem;
      font-size: x-large;
      color: ${(props) => props.theme.article?.heading};
    }

    & > h3 {
      margin-top: 1.5rem;
      font-size: larger;
      color: ${(props) => props.theme.article?.heading};
    }

    & > h4,
    & > h5,
    & > h6 {
      margin-top: 1rem;
      font-size: large;
      color: ${(props) => props.theme.article?.heading};
    }

    & > strong {
      color: ${(props) => props.theme.article?.emphasis};
    }

    & > figure {
      max-width: unset;

      figcaption {
        color: ${(props) => props.theme.article?.caption};
      }
    }

    & > .math.math-display {
      overflow-x: auto;
    }
  }
`;

type DataProps = {
  sanityArticle: {
    _createdAt: string;
    _updatedAt: string;
    title: string;
    subtitle: string;
    image: string;
    lang: string;
    tags: {
      tag_id: {
        current: string;
      };
      label_en: string;
      label_ja: string;
    }[];
    children: {
      htmlAst: Element;
    }[];
  };
};

export default function ArticlePage(props: PageProps<DataProps>) {
  const {
    _createdAt: createdAt,
    _updatedAt: updatedAt,
    title,
    subtitle,
    image,
    tags,
    children,
  } = props.data.sanityArticle;

  const [siteLang] = useRecoilState(langAtom);
  const { htmlAst } = children[children.length - 1] ?? {};
  if (htmlAst) {
    htmlAst.type = "element";
    htmlAst.tagName = "article";

    const articleContent = unified()
      .use(rehypeParse)
      .use(rehypeReact, {
        createElement,
        Fragment,
        components: {
          img: Image,
          a: ExternalLink,
          blockquote: BlockQuote,
          admonition: Admonition,
          treeview: TreeView,
          div: DivProxy,
        },
      })
      .stringify(h(null, htmlAst));
    return (
      <ArticlePageStyles>
        <div className="article-front">
          <HeadImage src={image} />
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          <div className="article-meta">
            <p>
              <svg className="meta-icon" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19 3H18V1H16V3H8V1H6V3H5C3.9 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.89 20.11 3 19 3M19 19H5V9H19V19M19 7H5V5H19M7 11H12V16H7"
                />
              </svg>
              {i18n("articles.created")}:{" "}
              {dayjs(createdAt).locale(siteLang).format("LL")}
            </p>
            <p>
              <svg className="meta-icon" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                />
              </svg>
              {i18n("common.updated")}:{" "}
              {dayjs(updatedAt).locale(siteLang).format("LL")}
            </p>
            <div className="article-tags">
              {tags.map((tag) => (
                <AniLink
                  fade
                  to={`/tags/${tag.tag_id.current}`}
                  key={tag.tag_id.current}
                >
                  <Tag
                    label={siteLang === "ja" ? tag.label_ja : tag.label_en}
                    id={tag.tag_id.current}
                  />
                </AniLink>
              ))}
            </div>
          </div>
          <hr />
        </div>
        {articleContent}
      </ArticlePageStyles>
    );
  }
  return <>Page</>;
}

export const query = graphql`
  query ($id: String) {
    sanityArticle(id: { eq: $id }) {
      _createdAt
      _updatedAt
      title
      subtitle
      image
      lang
      tags {
        tag_id {
          current
        }
        label_en
        label_ja
      }
      children {
        ... on MarkdownRemark {
          htmlAst
        }
      }
    }
  }
`;

export { default as Head } from "../../core/Head";
