import styled from "@emotion/styled";
import React from "react";

const AdmonitionStyles = styled.div`
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  padding-left: 3rem;
  border-radius: 0.5rem;

  h5 {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    margin-top: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.admonition-info {
    h5::before {
      content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22${(
        props,
      ) =>
        encodeURIComponent(
          props.theme.article?.admInfoText,
        )}%22%20d%3D%22M256%200C114.6%200%200%20114.6%200%20256s114.6%20256%20256%20256s256-114.6%20256-256S397.4%200%20256%200zM256%20128c17.67%200%2032%2014.33%2032%2032c0%2017.67-14.33%2032-32%2032S224%20177.7%20224%20160C224%20142.3%20238.3%20128%20256%20128zM296%20384h-80C202.8%20384%20192%20373.3%20192%20360s10.75-24%2024-24h16v-64H224c-13.25%200-24-10.75-24-24S210.8%20224%20224%20224h32c13.25%200%2024%2010.75%2024%2024v88h16c13.25%200%2024%2010.75%2024%2024S309.3%20384%20296%20384z%22%2F%3E%3C%2Fsvg%3E");
      display: inline-block;
      position: absolute;
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }
    background-color: ${(props) => props.theme.article?.admInfoBase};
    color: ${(props) => props.theme.article?.admInfoText};
    border-left: 4px solid ${(props) => props.theme.article?.admInfoText};
  }

  &.admonition-success {
    h5::before {
      content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22${(
        props,
      ) =>
        encodeURIComponent(
          props.theme.article?.admSuccessText,
        )}%22%20d%3D%22M0%20256C0%20114.6%20114.6%200%20256%200C397.4%200%20512%20114.6%20512%20256C512%20397.4%20397.4%20512%20256%20512C114.6%20512%200%20397.4%200%20256zM371.8%20211.8C382.7%20200.9%20382.7%20183.1%20371.8%20172.2C360.9%20161.3%20343.1%20161.3%20332.2%20172.2L224%20280.4L179.8%20236.2C168.9%20225.3%20151.1%20225.3%20140.2%20236.2C129.3%20247.1%20129.3%20264.9%20140.2%20275.8L204.2%20339.8C215.1%20350.7%20232.9%20350.7%20243.8%20339.8L371.8%20211.8z%22%2F%3E%3C%2Fsvg%3E");
      display: inline-block;
      position: absolute;
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }
    background-color: ${(props) => props.theme.article?.admSuccessBase};
    color: ${(props) => props.theme.article?.admSuccessText};
    border-left: 4px solid ${(props) => props.theme.article?.admSuccessText};
  }

  &.admonition-warning {
    h5::before {
      content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22${(
        props,
      ) =>
        encodeURIComponent(
          props.theme.article?.admWarningText,
        )}%22%20d%3D%22M506.3%20417l-213.3-364c-16.33-28-57.54-28-73.98%200l-213.2%20364C-10.59%20444.9%209.849%20480%2042.74%20480h426.6C502.1%20480%20522.6%20445%20506.3%20417zM232%20168c0-13.25%2010.75-24%2024-24S280%20154.8%20280%20168v128c0%2013.25-10.75%2024-23.1%2024S232%20309.3%20232%20296V168zM256%20416c-17.36%200-31.44-14.08-31.44-31.44c0-17.36%2014.07-31.44%2031.44-31.44s31.44%2014.08%2031.44%2031.44C287.4%20401.9%20273.4%20416%20256%20416z%22%2F%3E%3C%2Fsvg%3E");
      display: inline-block;
      position: absolute;
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }
    background-color: ${(props) => props.theme.article?.admWarningBase};
    color: ${(props) => props.theme.article?.admWarningText};
    border-left: 4px solid ${(props) => props.theme.article?.admWarningText};
  }
  &.admonition-danger {
    h5::before {
      content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22${(
        props,
      ) =>
        encodeURIComponent(
          props.theme.article?.admDangerText,
        )}%22%20d%3D%22M256%200C114.6%200%200%20114.6%200%20256s114.6%20256%20256%20256s256-114.6%20256-256S397.4%200%20256%200zM232%20152C232%20138.8%20242.8%20128%20256%20128s24%2010.75%2024%2024v128c0%2013.25-10.75%2024-24%2024S232%20293.3%20232%20280V152zM256%20400c-17.36%200-31.44-14.08-31.44-31.44c0-17.36%2014.07-31.44%2031.44-31.44s31.44%2014.08%2031.44%2031.44C287.4%20385.9%20273.4%20400%20256%20400z%22%2F%3E%3C%2Fsvg%3E");
      display: inline-block;
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 1rem;
      height: 1rem;
    }
    background-color: ${(props) => props.theme.article?.admDangerBase};
    color: ${(props) => props.theme.article?.admDangerText};
    border-left: 4px solid ${(props) => props.theme.article?.admDangerText};
  }
`;

function Admonition(props: React.HTMLProps<HTMLDivElement>) {
  const defaultTitle = {
    info: "Info",
    success: "Success",
    warning: "Warning",
    danger: "Danger",
  };
  const admonitionType =
    (props.className?.match(
      /admonition-(\w+)/,
    )?.[1] as keyof typeof defaultTitle) || "info";
  const hasTitle = React.Children.toArray(props.children).at(0)?.type === "h5";

  return (
    <AdmonitionStyles {...props}>
      {!hasTitle && <h5>{defaultTitle[admonitionType]}</h5>}
      {props.children}
    </AdmonitionStyles>
  );
}

export default Admonition;
