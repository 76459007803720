import React from "react";
import Admonition from "./Admonition";

function DivProxy(props: React.HTMLProps<HTMLDivElement>) {
  // Pick between different div implementations, and default to normal div.

  if (props.className?.includes("admonition")) {
    return <Admonition {...props} />;
  }

  return <div {...props} />;
}

export default DivProxy;
