import styled from "@emotion/styled";
import React from "react";

type HeadImageProps = {
  src: string;
};

const HeadImageStyles = styled.div<HeadImageProps>`
  background-image: url("${(props) => props.src}");
  height: 50vh;
  max-height: 800px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
`;

function HeadImage(props: HeadImageProps & React.HTMLProps<HTMLDivElement>) {
  return <HeadImageStyles {...props} className="head-image" />;
}

export default HeadImage;
