import React, { useState } from "react";
import styled from "@emotion/styled";
import CodeIcon from "./CodeIcon";

// Types & Interfaces

type View = {
  name: string;
  children: (
    | string
    | number
    | boolean
    | unknown
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactPortal
  )[];
};

type NodeProps = {
  id: string;
  icon: string;
  name: string;
  expanded: boolean;
  children: NodeProps[];
  currentView: string;
  setCurrentView: React.Dispatch<any>;
};

interface TreeViewProps extends React.HTMLProps<HTMLDivElement> {
  tree: string;
}

// Styles

const TreeViewStyles = styled.div`
  display: flex;
  height: 30vh;
  min-height: 300px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;

  * {
    transition: all 200ms;
  }

  /* Hierarchy listing */
  .tree-view-hierarchy {
    width: 30%;
    margin: 10px;
    padding-left: 0;

    list-style-type: none;
    overflow: auto;
  }

  /* Content */
  .tree-view-content {
    width: 70%;
    padding: 0.5rem;
    border-left: 1px solid #e5e7eb;
    overflow: auto;

    background-color: ${(props) => props.theme.code?.codeBackground};
    pre {
      margin: 0 10px;
    }
  }
`;

type NodeStyleProps = {
  name: string;
  currentView: string;
};

const NodeStyles = styled.li<NodeStyleProps>`
  div {
    display: flex;
  }

  button {
    text-align: left;
    flex-grow: 1;
    background: none;
    border: none;
  }

  ul {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    list-style-type: none;
    border-left: 1px solid transparent;
    overflow: hidden;

    &:hover {
      border-left: 1px solid #d5d7db;
    }

    &.collapsed {
      display: none;
    }
  }

  ${(props) =>
    props.currentView === props.id &&
    `
  background-color: #f3f3f3;
  button {
    font-weight: bold;
  }
  `}
`;

// Components

function Node(props: NodeProps) {
  const [isOpen, setOpen] = useState(props.expanded || false);

  const onClick = () => {
    if (props.children?.length) {
      setOpen(!isOpen);
    } else {
      props.setCurrentView(props.id);
    }
  };

  return (
    <NodeStyles {...props}>
      <div>
        <CodeIcon name={props.icon} />
        <button onClick={onClick}>{props.name}</button>
      </div>
      {props.children && (
        <ul className={isOpen ? "expanded" : "collapsed"}>
          {props.children.map((node) => (
            <Node
              {...node}
              key={node.id}
              expanded={props.expanded}
              currentView={props.currentView}
              setCurrentView={props.setCurrentView}
            />
          ))}
        </ul>
      )}
    </NodeStyles>
  );
}

function TreeView(props: TreeViewProps & React.HTMLProps<HTMLDivElement>) {
  const { tree, ...rest } = props;

  // Generate view hierarchy
  const treeStructure = JSON.parse(props.tree) as NodeProps[];

  // Collect children into views
  const views: View[] = [];
  const children = React.Children.toArray(props.children);
  children.forEach((child) => {
    if (React.isValidElement(child) && child.type === "view") {
      views.push({
        name: child.props.name,
        children: [],
      });
    } else if (child) {
      views[views.length - 1]?.children.push(child);
    }
  });

  const [currentView, setCurrentView] = useState<string>(views[0]?.name || "");

  return (
    <TreeViewStyles className="tree-view" {...rest}>
      <ul className="tree-view-hierarchy">
        {treeStructure.map((node) => (
          <Node
            {...node}
            key={node.id}
            expanded={true}
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        ))}
      </ul>
      <div className="tree-view-content">
        {views.find((view) => view.name === currentView)?.children}
      </div>
    </TreeViewStyles>
  );
}

export default TreeView;
