import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
  name: string;
}

const IconStyles = styled.span`
  object,
  img {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: top;
  }
`;

function Icon(props: IconProps) {
  const theme = useTheme();
  return (
    <IconStyles>
      <object
        data={`/icons/${props.name}${theme.isLight ? "_light" : ""}.svg`}
        type="image/svg+xml"
        role="presentation"
      >
        <img src={`/icons/${props.name}.svg`} alt="" />
      </object>
    </IconStyles>
  );
}

export default Icon;
