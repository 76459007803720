import React from "react";
import styled from "@emotion/styled";

export default styled.blockquote`
  position: relative;
  border-left: solid ${(props) => props.theme.article?.quoteBorder} 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;
  font-style: italic;

  &::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20448%20512%22%3E%3Cpath%20fill=%22${(
      props,
    ) =>
      encodeURIComponent(
        props.theme.article?.quoteIcon,
      )}%22%20d=%22M96%20224C84.72%20224%2074.05%20226.3%2064%20229.9V224c0-35.3%2028.7-64%2064-64c17.67%200%2032-14.33%2032-32S145.7%2096%20128%2096C57.42%2096%200%20153.4%200%20224v96c0%2053.02%2042.98%2096%2096%2096s96-42.98%2096-96S149%20224%2096%20224zM352%20224c-11.28%200-21.95%202.305-32%205.879V224c0-35.3%2028.7-64%2064-64c17.67%200%2032-14.33%2032-32s-14.33-32-32-32c-70.58%200-128%2057.42-128%20128v96c0%2053.02%2042.98%2096%2096%2096s96-42.98%2096-96S405%20224%20352%20224z%22/%3E%3C/svg%3E");
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 0.5rem;
    top: 1rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20448%20512%22%3E%3Cpath%20fill=%22${(
      props,
    ) =>
      encodeURIComponent(
        props.theme.article?.quoteIcon,
      )}%22%20d=%22M96%2096C42.98%2096%200%20138.1%200%20192s42.98%2096%2096%2096c11.28%200%2021.95-2.305%2032-5.879V288c0%2035.3-28.7%2064-64%2064c-17.67%200-32%2014.33-32%2032s14.33%2032%2032%2032c70.58%200%20128-57.42%20128-128V192C192%20138.1%20149%2096%2096%2096zM448%20192c0-53.02-42.98-96-96-96s-96%2042.98-96%2096s42.98%2096%2096%2096c11.28%200%2021.95-2.305%2032-5.879V288c0%2035.3-28.7%2064-64%2064c-17.67%200-32%2014.33-32%2032s14.33%2032%2032%2032c70.58%200%20128-57.42%20128-128V192z%22/%3E%3C/svg%3E");
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    right: 0.5rem;
    bottom: 2rem;
  }
`;
